<template>
  <div>
    <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
      <div class="banner perBan">
        <img :src="banners.image" alt="">
        <div class="w1440">
          <div class="txt">
            <p class="fnt_52">
              {{ globalDataArray.siteTemplateLanguage.banner_welcome }}
              <template v-if="saitiAccessInfo.username!=''&&saitiAccessInfo.username!=null">
                {{ saitiAccessInfo.username }}
              </template>
              <template v-else-if="saitiAccessInfo.nickname!=''&&saitiAccessInfo.nickname!=null">
                {{ saitiAccessInfo.nickname }}
              </template>
            </p>
            <span class="fnt_18 mail" v-if="saitiAccessInfo.email!=''&&saitiAccessInfo.email!=null">
              {{ saitiAccessInfo.email }}
            </span>
            <span class="fnt_18" v-else-if="saitiAccessInfo.phone!=''&&saitiAccessInfo.phone!=null">
              {{ saitiAccessInfo.phone }}
            </span>
          </div>
        </div>
      </div>

      <div class="personPage">
        <div class="cf w1440">
          <div class="personLeft">
            <personmenu />
          </div>

          <div class="personRight">
            <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_review_record }}</div>

            <div class="antDesignTable">
              <a-table
                row-key="id"
                :scroll="{x:true}"
                :columns="columns"
                :data-source="list"
                :pagination="paginationOpt"
                class="j-table-force-nowrap"
              >
                <template slot="tit" slot-scope="text, record">
                  <span @click="seeInfo(record)" class="compNameBtn">{{ text }}</span>
                </template>
                <template slot="checkStatus" slot-scope="text, record">
                  <span class="statusfont">{{ record.checkStatus_dictText }}</span>
                </template>
                <template slot="action" slot-scope="text, record">
                  <span v-if="record.checkStatus=='02'" @click="examInfo(record)" class="compExamineBtn">{{ globalDataArray.siteTemplateLanguage.audit }}</span>
                  <span v-if="record.checkStatus=='04'||record.checkStatus=='03'" @click="seeInfo(record)" class="compExamineBtn">{{ globalDataArray.siteTemplateLanguage.select }}</span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <personexamine :personexamine.sync="personexamine" :is-exam.sync="isExam" :curid.sync="curid" @itemclick="examineResFun" />
  </div>
</template>

<script>
import { examineRecord } from '@/api/person/person'
import { getAdList } from '@/api/api'
import Cookies from 'js-cookie'
export default {
  name: 'Myexamine',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,
      saitiAccessInfo: '',
      paginationOpt: {
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: 10,
        pageSize: 10,
        hideOnSinglePage: true,
        onChange: (current, size) => {
          this.defaultPageSize = size
          this.paginationOpt.current = current
          this.examineRecord()
        },
        // onChange:在分页数量和当前页发生变化时触发
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.pageSize = pageSize
        }
      },

      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 80,
          align: 'center',
          customRender: (text, record, index) => {
            return ((this.paginationOpt.current - 1) * this.paginationOpt.pageSize + index + 1)
          }
        },
        {
          title: '赛事名称',
          align: 'center',
          dataIndex: 'competitionTitle',
          scopedSlots: { customRender: 'tit' }
        },
        {
          title: '申请人',
          align: 'center',
          dataIndex: 'userName'
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'userPhone'
        },
        {
          title: '申请人邮箱',
          align: 'center',
          dataIndex: 'userMail'
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'checkStatus',
          scopedSlots: { customRender: 'checkStatus' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],

      list: [],

      personexamine: false,
      curid: {},
      isExam: false,
      banners: {}
    }
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getAdList()
      this.tableHeader()
      this.examineRecord()

      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        this.saitiAccessInfo = JSON.parse(Cookies.get('saiti_access_info'))
      }
    },
    tableHeader() {
      const that = this
      that.columns[0].title = that.globalDataArray.siteTemplateLanguage.person_table_number
      that.columns[1].title = that.globalDataArray.siteTemplateLanguage.person_table_competition_title
      that.columns[2].title = that.globalDataArray.siteTemplateLanguage.team_applicant
      that.columns[3].title = that.globalDataArray.siteTemplateLanguage.phone
      that.columns[4].title = that.globalDataArray.siteTemplateLanguage.team_apply_user_email
      that.columns[5].title = that.globalDataArray.siteTemplateLanguage.apply_time
      that.columns[6].title = that.globalDataArray.siteTemplateLanguage.person_table_status
      that.columns[7].title = that.globalDataArray.siteTemplateLanguage.person_table_controls
      // console.log(this.columns,'columns')
    },
    examineRecord() {
      const that = this
      const param = 'page=' + that.paginationOpt.defaultCurrent + '&pageSize=' + that.paginationOpt.defaultPageSize
      examineRecord(param).then(res => {
        if (res.success) {
          that.list = res.result.records
          that.paginationOpt.total = res.result.total
        }
      })
    },
    getAdList() {
      const that = this
      const adParam = 'alias=person_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          that.banners = res.result[0]
        }
      })
    },
    // 审核
    examInfo(id) {
      const that = this
      that.personexamine = true
      that.curid = id
      that.isExam = true
    },
    // 查看
    seeInfo(record) {
      const that = this
      that.personexamine = true
      that.curid = record
      that.isExam = false
    },

    // 审核结果
    examineResFun(res) {
      const that = this
      that.examineRecord()

      // 驳回
      if (res == '0') {
      }

      // 通过
      if (res == '1') {
      }
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/person.css'
</style>
